@import "../../../../assets/styles/scss/variables";

.small-sidebar,
.sidebar {
	color: $primary;
	font-size: 12px;
	font-weight: 600;
	background: $white;
	overflow-y: auto;
	box-shadow: 0 0 27px 0 rgba(214, 231, 233, 0.52);

	a {
		color: unset !important;
	}
	button {
		&.sidebar__nav__item__link {
			background-color: inherit;
		}
		&.sidebar__nav__item__link:focus {
			outline: 0;
		}
	}

	.sidebar__header,
	.navbar-toggler {
		padding: 1.3rem 0.5rem 2.5rem 3rem;
		cursor: pointer;
	}
	.sidebar__footer {
		width: 100%;
		position: absolute;
		bottom: 0;
		background: $primary;
		color: $white;
	}
	.sidebar__nav__item__link {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 1.3rem 0.5rem 1.3rem 3rem;
		position: relative;
		line-height: 1.5;

		svg {
			width: 18px;
			path {
				// stroke: $primary-deep;
			}
		}
	}
	.sidebar__nav__item__link {
		text-decoration: none;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			width: 3px;
			max-height: 0;
			height: 100%;
			transition: all 300ms linear;
		}
		&:hover,
		&[data-active="true"] {
			cursor: pointer;
			color: $primary-deep;
			font-family: gilroy-bold;

			svg {
				path {
					// fill: $primary-deep;
					stroke: $primary-deep;
				}
			}
			&::after {
				max-height: 100%;
				background: $primary-grad;
				border-radius: 3px 0px 0px 3px;
			}
		}
	}
}

.navbar-toggler {
	// width: 20%;
	display: flex;
	justify-content: center;

	&:focus {
		box-shadow: none !important;
		border: none !important;
	}

	&.primary,
	&.primary.collapsed {
		.toggler-icon {
			background: $primary-light;
		}
	}

	.toggler-icon {
		width: 22px;
		height: 2px;
		background: $primary-light;
		display: block;
		transition: all 0.5s;
	}

	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
	}

	.middle-bar {
		opacity: 0;
		filter: alpha(opacity=0);
		margin: 4px auto 4px 0px;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
	}

	&.collapsed {
		.top-bar {
			transform: rotate(0);
		}

		.middle-bar {
			opacity: 1;
			filter: alpha(opacity=100);
		}

		.bottom-bar {
			transform: rotate(0);
		}

		.toggler-icon {
			background-color: $primary-light;
		}
	}
}
.small-sidebar {
	border-right: 1px solid $border;

	.navbar-toggler {
		padding: 1.4rem 1rem;
		justify-content: center;
	}

	.sidebar__nav__item__link,
	.sidebar__header {
		padding: 1.4rem 1rem;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.sidebar__header {
		padding-bottom: 2.5rem;
	}
	.sidebar__nav__item__link:hover,
	.sidebar__nav__item__link[data-active="true"] {
		border-left: unset;
	}
}
