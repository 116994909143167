@import "assets/styles/scss/variables.scss";
.watcher-reg-card {
  background-color: $white;
  border-radius: 2px;
  .flex__between {
    .text-desc {
      h6 {
        .ml-4 {
          margin-left: 8px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          color: $gray-muted;
          text-transform: uppercase;
        }
      }
      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
      }
    }
    .icon-desc {
      color: $gray-muted;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      .spike-wrapper {
        height: 50px;
        width: 50px;
        background: $success-transparent;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-direction: column;
        color: $success;
        span {
          margin-top: 10px;
        }
      }
    }
  }
}
