@import "assets/styles/scss/variables.scss";

.title {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: $gray-muted;
	font-family: "gilroy-bold";
}

.bg-white {
	background-color: $white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
	border-radius: 2px;
}

.h-412 {
	height: 412px;
}

.progress-heading {
	margin-top: 26px;
}

.progress-title {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #39444f;
	margin: 0;
}

.progress-amount {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #39444f;
}
.progress {
	height: 15px !important;
}

.active-merchant-progress.progress-bar {
	background: #509fef;
}
