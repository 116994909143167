@import "assets/styles/scss/variables";

.auth-container {
  width: 100vw;
  height: 100vh;
  background: url("../../images/auth-bg.png");
  background-size: cover;
  .auth-card {
    padding: 40px;
    background-color: $white;
    height: 500px;
    flex-direction: column;
    h5 {
      color: $gray-muted;
    }
    form {
      width: -webkit-fill-available;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 500px) {
  .auth-container {
    .auth-card {
      padding: 40px 20px;
      height: 450px;
      h5 {
        color: $gray-muted;
      }
      form {
        width: -webkit-fill-available;
        margin-bottom: 30px;
      }
    }
  }
}
