// HEADINGS

.heading-1 {
	font-size: 18px;
	font-weight: 600;
}

.heading-2 {
	font-size: 16px;
	font-weight: 600;
}

.heading-3 {
	font-size: 14px;
	font-weight: 600;
}

.heading-4 {
	font-size: 12px;
	font-weight: 600;
}

.del-categ-heading {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.1px;
	color: $light-black;
	font-family: "gilroy-medium";
	span {
		color: $black;
	}
}

// TEXT SIZES

.text--xl {
	font-size: 32px;

	@media (max-width: 991.98px) {
		font-size: 25px;
	}
}

.text--xlg {
	font-size: 28px;

	@media (max-width: 991.98px) {
		font-size: 23px;
	}
}

.text--lg {
	font-size: 24px !important;
}

.text--xslg {
	font-size: 20px !important;
}

.text--md {
	font-size: 16px !important;
}

.text--sm {
	font-size: 10px;
}

.text--secondary {
	color: $secondary !important;
}

.text--primary {
	color: $primary !important;
}

.ml-auto {
	margin-left: auto;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

// FONT FAMILIES

.gilroy-regular-italic {
	font-family: "gilroy-regular-italic", sans-serif;
}

.gilroy-bold {
	font-family: "gilroy-bold", sans-serif;
}

.roboto-regular {
	font-family: "roboto-regular", sans-serif;
	font-weight: 400;
}
.team-text {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.1px;
	color: $auth-gray;
	font-family: "gilroy-medium";
}

@media (max-width: 575.98px) {
	.text--mobile-sm {
		font-size: 10px !important;
	}
}
