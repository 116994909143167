@import "assets/styles/scss/variables";

.container {
	display: flex;
	--sidebar-width: 200px;
	--header-height: 50px;
	--small-sidebar-width: 60px;
}

.main {
	flex: 1;
	position: absolute;
	width: calc(100% - var(--small-sidebar-width));
	min-height: 100%;
	right: 0;
	overflow: sroll;
	transition: all 0.5s ease 0s;
}

.content {
	min-height: calc(100vh - var(--header-height));
	padding: 10px 20px 40px 20px;
}

.sidebar,
.small-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	overflow-x: hidden;
	transition: all 0.5s ease 0s;
}

.sidebar {
	flex: 0 1 var(--sidebar-width);
	min-width: var(--sidebar-width);
	max-width: var(--sidebar-width);
	margin-left: calc(-1 * var(--sidebar-width));
	z-index: 2;
	padding-top: 10px;

	.navbar-toggler {
		justify-content: start;
	}

	&.active {
		margin-left: 0;
	}
}
.small-sidebar {
	padding-top: 12px;
	min-width: var(--small-sidebar-width);
	max-width: var(--small-sidebar-width);
	margin-left: calc(-1 * var(--small-sidebar-width));
	z-index: 3;
	&.active {
		margin-left: 0;
	}
}

.header {
	width: 100%;
	min-height: var(--header-height);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px 0 20px;
	border-bottom: 1px solid $border;

	.tabbed__header {
		display: flex;
		align-items: center;

		a {
			padding: 0 15px;
			font-size: 12px;
			white-space: nowrap;
			color: $dark !important;
			letter-spacing: 0.15px;
			text-decoration: none;
			margin: 0;
			position: relative;
			padding-bottom: 17px;

			&.active {
				color: $primary !important;
				font-weight: 600;
				border-bottom: 2px solid $primary-deep;
				&::before {
					width: 100%;
				}
			}
		}
	}

	.right__headers {
		a {
			border: 1px solid #f5f6f6;
			border-radius: 4px;
			padding: 10px 5px;
			margin: 0 5px;
		}
		svg {
			width: 20px;
			height: 20px;
		}
	}
}
.bg-main {
	background: $gray-accent;
}
@media (min-width: 1024px) {
	.main {
		width: calc(100% - var(--sidebar-width));
		&.active {
			width: calc(100% - var(--small-sidebar-width));
		}
	}
}

@media (max-width: 600px) {
	.content {
		padding: 10px;
	}

	.header {
		padding: 10px;

		.right__headers {
			svg {
				margin-left: 10px;
			}
		}
	}
}
