@import "assets/styles/scss/variables";

// Input Field
.form-group {
  font-size: 1em;
  margin-bottom: 2rem;

  .question {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    border-radius: 50%;
    border: 1px solid $gray-med;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    cursor: pointer;
  }

  input {
    padding: 0.8rem 1rem;
    line-height: inherit;
    font-style: normal;
    color: inherit;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &::placeholder {
      font: inherit;
      line-height: inherit;
      color: $gray-light;
    }

    &:read-only {
      cursor: not-allowed;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .form-group-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;

    &.required {
      &::after {
        content: "*";
        color: $danger;
        margin-left: 3px;
      }
    }
  }

  .input-wrapper {
    border: 1px solid #ebebf5;
    border-radius: 2px;

    &.error {
      border: 2px solid $danger;
    }
    &.disabled {
      border: none;
    }
  }

  .password-input-wrapper img {
    cursor: pointer;
  }

  .input-text-error {
    font-size: 0.9em;
    color: $danger;
  }
}

.show__password svg path {
  fill: $gray !important;
}
