@import "./variables", "./mixins";

// FILTER INPUTS

.table-filter {
  #filter-form {
    .form-group {
      .form-group-label {
        color: $auth-gray;
        font-weight: 600;
      }

      input {
        font-size: 14px;
        height: 30px;
        padding: 8px 10px;
      }

      .date-input {
        .input-wrapper {
          background-color: $faint-gray;

          input {
            background-color: $faint-gray;
          }

          svg {
            fill: $faint-gray !important;
          }
        }
      }

      .custom-check-box {
        margin-bottom: 0px;
        padding: 10px;

        &:hover {
          background-color: $faint-gray;
        }

        input {
          background-color: $white;
          border: 1px solid #ebebf5;
          height: 16px;
          padding: 0;
          width: 16px;

          &:checked {
            background-color: $primary-deep;
          }
        }

        label {
          font-family: "gilroy-regular";
        }
      }

      &:hover,
      &:focus-within {
        .form-group-label {
          color: $primary;
        }
      }
    }
  }
}

.custom__date {
  position: absolute;
  top: 40px;
  z-index: 9999;
  right: 0;
  padding: 20px 10px 0 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
  font-size: 1.5em;
}

// .react-datepicker,
.react-datepicker__current-month {
  padding: 0 14px 14px !important;
  font-size: 1.5em;
}
.react-datepicker__month-dropdown-container,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container,
.react-datepicker__year-dropdown-container--select {
  padding: 14px !important;
  font-size: 1.5em !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  padding: 3px 14px !important;
}

.react-datepicker,
.react-datepicker * {
  border: 0px !important;
  background-color: $white !important;
  color: $auth-gray !important;

  &:hover {
    background-color: $white !important;
    z-index: 99999999 !important;
  }

  .react-datepicker__day--selected {
    // background-color: $primary !important;
    color: $primary !important;
    font-family: "gilroy-bold";
    font-size: 1.6rem;
  }
}

.react-datepicker svg *,
.react-datepicker__day-name {
  color: $auth-gray !important;
}
.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}

.react-datepicker__day--disabled {
  color: #ddd !important;
}



// SMALL SELECT

.MuiOutlinedInput-input {
	padding: 6px 6px 7px 8px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
}
.MuiOutlinedInput-notchedOutline {
	top: -2px !important;
	border: 1px solid $gray !important;
}
.MuiFormControl-root {
	min-width: 100% !important;
	display: inline-block;
}
.MuiSelect-icon {
	top: unset !important;
}
.MuiInputBase-root {
	font-size: unset !important;
}
.MuiInputBase-input {
	height: unset !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid $gray !important;
	background-color: unset !important;
}

.MuiSelect-select {
	&:focus {
		background: unset !important;
	}
}

// DATE SELECT

.date-select {
	border: 1px solid $light-gray;
	border-radius: 2px;

	.calender-icon-container {
		background-color: $faint-gray;
		border-right: 1px solid $light-gray;
		padding: 10px 0px;
		height: 100%;
	}

	.MuiOutlinedInput-input {
		padding: 6px 6px 7px 8px !important;
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
		width: 100% !important;
	}
	.MuiOutlinedInput-notchedOutline {
		top: -2px !important;
		border: 1px solid transparent !important;
	}
	.MuiFormControl-root {
		min-width: 100% !important;
		display: inline-block;
	}
	.MuiSelect-icon {
		top: unset !important;
	}
	.MuiInputBase-root {
		font-size: unset !important;
		width: 100% !important;

		.MuiSvgIcon-root {
			margin: 0px 10px !important;
		}
	}
	.MuiInputBase-input {
		height: unset !important;
	}
	.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid transparent !important;
		background-color: unset !important;
	}

	.MuiSelect-select {
		text-overflow: ellipsis;

		&:focus {
			background: unset !important;
		}
	}
}