@import "./variables", "./mixins";

// BACKGROUNDS

.bg--primary {
	background-color: $primary;
}

.bg--secondary {
	background-color: $secondary;
}

.bg--faded-secondary {
	background-color: $faded-secondary;
}

.bg--success {
	background-color: $success;
}

.bg--danger {
	background-color: $danger;
}

.bg-danger-transparent {
	background-color: $danger-transparent;
}

.bg--error {
	background-color: $error;
}

.bg--warning {
	background-color: $warning;
}

.bg--white {
	background-color: $white;
}

.bg--faint-gray {
	background-color: $faint-gray;
}

.bg--faint-primary {
	background-color: $faded-secondary;
}

.bg--faint-secondary {
	background-color: $faded-secondary;
}

.bg--light-black {
	background-color: $light-black;
}

// PILLS

.pill--success {
	@include status__pill;
	background-color: $success-transparent;
	border: 1px solid $success-transparent;
	color: $success;
}

.pill--warning {
	@include status__pill;
	background-color: $warning-transparent;
	border: 1px solid $warning-transparent;
	color: $warning;
}

.pill--danger {
	@include status__pill;
	background-color: $danger-transparent;
	border: 1px solid $danger-transparent;
	color: $danger;
}

// FILTERS

.filters__container {
	position: relative;
}

.table-filter {
	background-color: $white;
	border: 1px solid $faint-gray;
	border-radius: 3px;
	// box-shadow: 0px 4px 9px #0000000a;
	height: 350px;
	position: absolute;
	right: 10px;
	top: 50px;
	width: 273px;
	z-index: 10;

	#filter-form {
		margin-bottom: 60px;
		padding: 10px 20px;
		max-height: 280px;
		overflow-y: scroll;

		.status--success {
			@include status--circle;

			circle {
				fill: #2ccfa2;
			}
		}

		.status--pending {
			@include status--circle;

			circle {
				fill: #f5d495;
			}
		}

		.status--failed {
			@include status--circle;

			circle {
				fill: #f18a87;
			}
		}
	}

	#filter-footer {
		bottom: 0;
		position: absolute;
		flex: 0 0 auto;
		padding: 1rem 1.5rem;
		width: 100%;
		z-index: 20;
		background: $white;
		@include flex__end;
		@include border--top-gray;
	}
}

// NEUTRAL COLORS

.black,
.black:hover {
	color: $black !important;

	circle,
	path {
		stroke: $black !important;
	}
}

.black-fill,
.black-fill:hover {
	color: $black !important;

	circle,
	path {
		fill: $black !important;
	}
}

.gray,
.gray:hover {
	color: $gray !important;

	circle,
	path {
		stroke: $gray !important;
	}
}

.gray-fill,
.gray-fill:hover {
	color: $gray;

	circle,
	path {
		fill: $gray;
	}
}

.dark {
	color: $dark;

	circle,
	path {
		fill: $dark;
	}
}

.white,
.white:hover {
	color: $white;

	path,
	circle {
		stroke: $white;
	}
}

.fill-white {
	color: $white;

	path,
	circle {
		fill: $white;
	}
}

.text-gray,
.text-gray:hover {
	color: $input-label;

	path,
	circle {
		fill: $input-label;
	}
}

.original-text-gray,
.original-text-gray:hover {
	color: $gray !important;

	path,
	circle {
		fill: $gray !important;
	}
}

.light-text-gray,
.light-text-gray:hover {
	color: $placeholder-gray !important;

	path,
	circle {
		fill: $placeholder-gray !important;
	}
}

.auth-text-gray,
.auth-text-gray:hover {
	color: $auth-gray !important;

	path,
	circle {
		fill: $auth-gray !important;
	}
}

.auth-text-gray--stroke,
.auth-text-gray--stroke:hover {
	color: $auth-gray !important;

	path,
	circle {
		stroke: $auth-gray !important;
		fill: transparent !important;
	}
}

.faint-text-gray,
.faint-text-gray:hover {
	color: $faint-gray !important;

	path,
	circle {
		fill: $faint-gray !important;
	}
}

.transparent-text-gray,
.transparent-text-gray:hover {
	color: $gray-transparent !important;

	path,
	circle {
		fill: $gray-transparent !important;
	}
}

.atm-card {
	display: flex;
	flex-direction: column;
	height: 200px;
	justify-content: space-between;
	border-radius: 10px;
	background: $primary;
	color: $faded-secondary;
	padding: 10px 18px;
	p {
		font-weight: bold;
		font-size: larger;
		span {
			font-weight: normal;
		}
	}
	div {
		h2 {
			font-weight: bold;
		}
	}
}
.recent-success-icon {
	rect,
	circle {
		stroke: $recent-success-icon;
	}
}

.recent-danger-icon {
	rect,
	circle {
		stroke: $recent-danger;
	}
}

.recent-warning-icon {
	rect,
	circle {
		stroke: $recent-warning-icon;
	}
}

.recent-blue-icon {
	rect,
	circle {
		stroke: $dark-blue;
	}
}

.recent-light-blue-icon {
	rect,
	circle {
		stroke: $very-ligt-grey;
	}
}
