@import "./variables";

.table-responsive {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table__wrapper {
  background-color: $white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  max-height: 930px;
  width: 100%;

  &.full__height {
    max-height: 1000000px;
  }
  &.faint__bg {
    background: $primary-muted;
    padding: 5px;
  }
  .thumb-horizontal {
    background-color: $danger;
    height: 10px !important;
  }

  &.table__head--gray {
    .table__head {
      background-color: $gray-light !important;
      border-radius: 2px;
      box-shadow: none !important;
    }
  }

  &.table__foot--gray {
    .table__foot {
      background-color: $gray-light !important;
      border-radius: 2px;
    }
  }

  &.table--sm {
    height: 300px;
  }

  .table {
    border-radius: 2px;
    position: relative;
    color: $black !important;
    margin-bottom: 0 !important;
    width: 100%;

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: $gray-accent;
    }

    .table__head {
      background-color: $gray-accent;
      font-weight: 900;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;

      th {
        color: $gray-fade;
        padding: 1rem 32px !important;
        border-bottom: 0 !important;
      }

      th,
      td {
        text-transform: uppercase !important;
        font-size: 14px;
        vertical-align: middle !important;
      }
    }

    .table__body {
      background-color: inherit;
      td {
        color: $black;
        font-weight: 500;
        font-size: 14px;
      }

      tr {
        box-shadow: 0px 1px 0px $gray-accent;

        &:hover {
          background-color: $gray-accent;

          .pill--success {
            border: 1px solid $success;
          }

          .pill--danger {
            border: 1px solid $danger;
          }

          .pill--warning {
            border: 1px solid $warning;
          }

          .btn--disabled-gray {
            border: 1px solid $gray;
          }
        }
      }

      img {
        max-height: 25px;
      }
    }

    .table__foot {
      background-color: inherit;

      tr {
        border-color: transparent !important;
        box-shadow: 0px 1px 0px $gray-light;

        &:hover {
          background-color: $gray-light;

          .pill--success {
            border: 1px solid $success;
          }

          .pill--danger {
            border: 1px solid $danger;
          }

          .pill--warning {
            border: 1px solid $warning;
          }
        }
      }

      td {
        color: $black;
        font-weight: 700;
      }
    }

    .table__body,
    .table__head,
    .table__foot {
      td {
        padding: 1rem 32px !important;
        border-top: 0 !important;
        font-size: 14px;
        vertical-align: middle;
      }

      tr {
        height: 50px;
      }
    }
  }

  .table__index {
    width: 30px;
    border: 1px solid $gray-light !important;
    padding: 0 10px;
    font-weight: 600;
    padding-top: 10px;
  }
}

table > :not(:first-child) {
  border-top: 1px solid transparent !important;
}

.table--recent-activity {
  border-radius: 2px;
  height: 283px;
  overflow: hidden;

  border-bottom: 1px solid $gray-light;

  .table tr {
    border-bottom: transparent;
  }
}
