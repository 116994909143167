@import "assets/styles/scss/variables.scss";
.watcher-reg-card {
  background-color: $white;
  border-radius: 2px;
  .flex__between {
    .text-desc {
      h6 {
        .ml-4 {
          margin-left: 8px;
        }
      }
    }
    p{
      font-weight: 700;
    }
    .icon-desc {
      color: $gray-muted;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        display: block;
      }
    }
  }
}
