@import "assets/styles/scss/variables.scss";

.transaction-distribution {
	background: $primary-muted;
	border-radius: 4px;
	h2 {
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		color: #82898f;
	}
	p {
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: #0e1b28;
	}
}
