@import "assets/styles/scss/variables.scss";
.watcher-reg {
  .col-4 {
    .col-inner {
      background-color: $primary-muted;
      .title {
        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $primary;
        }
      }
      .col-inner-body {
        overflow-y: scroll;
        max-height: 82vh;
      }
      .tag {
        background: $primary-deep;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 13px;
        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $white;
          margin: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .watcher-reg {
    width: 100vw;
    overflow-x: scroll;
    .row {
      flex-wrap: nowrap;
      .col-4 {
        min-width: 250px !important;
      }
    }
  }
}
@media (max-width: 500px) {
  .watcher-reg {
    width: 100vw;
    overflow-x: scroll;
    .row {
      flex-wrap: nowrap;
      .col-4 {
        min-width: 80% !important;
        .col-inner-body {
          overflow-y: scroll;
          max-height: 90vh;
        }
      }
    }
  }
}
