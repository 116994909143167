@import "./variables", "./mixins";

/** NavTabs **/

.nav-tabs-container {
	overflow-x: auto;
}

.underlined-tabs {
	@include border--bottom-gray;
}

.nav-tabs {
	background: inherit;
	border-bottom: none !important;
	display: flex;
	justify-content: flex-start;
	padding-right: 10px;

	&.space-between {
		justify-content: space-between;
	}

	li.nav-item,
	a.nav-item {
		align-items: center;
		display: flex;
		font-size: 14px;
		font-weight: 500;
		justify-content: center;
		text-align: center;
	}

	.nav-link {
		border-radius: 0px;
		box-sizing: border-box;
		color: $auth-gray;
		margin-bottom: 0;
		transition: all 500ms ease-in-out;
		white-space: nowrap;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		svg path {
			fill: $auth-gray;
		}

		&.active,
		&:hover:not(.active) {
			background: inherit;
		}
	}

	.bordered-nav-link {
		background-color: $white;
		border: 1px solid $faint-gray;
		font-weight: 500;
		min-width: 111px;
		padding: 10px 12px 10px 15px;

		&.active,
		&:hover:not(.active) {
			background-color: $white;
			border: 1px solid $primary;
			color: $primary;

			svg path {
				fill: $primary;
			}
		}
	}

	.underlined-nav-link {
		border: none;
		border-bottom: 2px solid transparent;
		min-width: 80px;
		padding: 25px 8px 13px;
		margin-right: 25px;
		font-weight: 700;

		&.active,
		&:hover:not(.active) {
			border-bottom: 2px solid $primary;
			color: $primary;

			svg path {
				fill: $primary;
			}
		}
	}

	.gray-nav-link {
		border: 1px solid $white;
		border-radius: 2px;
		padding: 10px 20px;

		&.active,
		&:hover:not(.active) {
			background-color: $faint-gray;
			border: 1px solid $white;
			color: $black;
		}
	}
}

@media (max-width: 500px) {
	.nav-wide-mobile {
		width: 100%;
	}
}
