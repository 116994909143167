@import "./variables";

@mixin border--gray {
	border: 1px solid $light-gray !important;
}
@mixin border--top-gray {
	border-top: 1px solid $light-gray;
}
@mixin border--right-gray {
	border-right: 1px solid $light-gray;
}
@mixin border--bottom-gray {
	border-bottom: 1px solid $light-gray;
}
@mixin status__pill {
	border-radius: 2px;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 600;
	min-height: 28px;
	min-width: 40px;
	max-width: 110px;
}

// FLEX

@mixin flex__between {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@mixin flex__start {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@mixin flex__end {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@mixin flex__justify__between {
	display: flex;
	justify-content: space-between;
}

@mixin flex__center {
	display: flex;
	justify-content: center;
	align-items: center;
}

// LAYOUTS

@mixin status__pill {
	border-radius: 2px;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 600;
	min-height: 28px;
	min-width: 40px;
	max-width: 110px;
}

// TRANSITIONS

@mixin rot-0 {
	transform: rotate(0deg);
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	transition: all 200ms linear;
}

@mixin rot-90 {
	transform: rotate(90deg);
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	transition: all 200ms linear;
}

@mixin rot-180 {
	transform: rotate(180deg);
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	transition: all 200ms linear;
}

@mixin rot-270 {
	transform: rotate(-90deg);
	-webkit-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-o-transition: all 200ms linear;
	transition: all 200ms linear;
}

// OTHERS

@mixin value {
	display: flex;
	padding-left: 5px;
	margin-bottom: 0px;
	word-break: break-all;
	flex-wrap: wrap;
	width: 50%;
}

// OVERFLOWS

@mixin overflow-x {
	-ms-overflow-style: none;
	flex-wrap: nowrap;
	scrollbar-width: none;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin overflow-y {
	-ms-overflow-style: none;
	flex-wrap: nowrap;
	scrollbar-width: none;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

// ASIDE STEP CONTAINER

@mixin aside-step-item {
	display: flex;
	flex: 0 0 auto;
	font-family: "gilroy-semibold";
	font-style: normal;
	margin: 0 0 25px 6.5%;

	@media (max-width: 991.98px) {
		margin: 0 0 30px 5%;
		width: 15%;
	}

	.title {
		color: $light-primary;
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		text-shadow: 0px 4px 7px #0000001a;
	}

	.subtitle {
		color: #2a6eac;
		line-height: 16px;
		font-size: 12px;
		font-weight: 500;
	}

	svg {
		circle {
			fill: #21629c;
		}

		path {
			fill: $gradient;
		}
	}

	&.current {
		.title {
			color: $white;
			font-family: "gilroy-bold";
			font-weight: 700;
		}

		.subtitle {
			color: #d5e8fb;
		}

		svg {
			circle {
				fill: $white;
			}

			path {
				fill: $primary;
			}
		}
	}

	&.prev {
		.title {
			color: #2cc7a2;
		}

		svg circle {
			fill: #2cc7a2;
		}
	}
}

// CIRCLE

@mixin status--circle {
	margin: 0 0.25rem 0.25rem 0;

	circle {
		cx: 7px;
		cy: 7px;
		r: 3.5px;
	}
}
