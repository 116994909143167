@import "./variables.scss", "./mixins";

// HEADER AND FOOTER

.header {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	align-items: center;
	background: $white;
	box-shadow: 0px 1px 22px var(--border-box-shadow);
	display: flex;
	min-height: var(--header-height);
	justify-content: space-between;
	padding: 15px 30px;
	width: 100%;

	.header__icon {
		cursor: pointer;
		margin-right: 20px;

		.header__badge {
			left: -13%;
			top: -9%;
			width: 8px;
		}
	}

	.header__title {
		color: $auth-gray;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		font-family: "gilroy-semibold";
	}
}
// CONTENT

.section--container {
	background-color: $white;
	padding: 20px 30px;

	.header {
		background-color: $white !important;
		box-shadow: none;
	}

	.default__header {
		padding: 26px 32px 0px;
	}

	.subHeading {
		color: $gray;
	}

	.section--stat-display {
		background-color: $faded-secondary;
		border-radius: 2px;
		padding: 25px 20px;
		min-height: 130px;

		@include border--gray;

		.subHeading {
			color: $auth-gray;
		}
	}

	.section--stat-highlight:hover {
		color: $primary;
		border: 2px solid $primary;
	}

	.section--body {
		padding: 47px 32px;
	}

	#wallet-balance {
		border-radius: 8px;
	}
}
.mb-52px {
	margin-bottom: 52px;
}
.section--margin {
	margin: 16px 8px 0;
}
#modal--aside-section,
#border-right-bottom--gray,
.user-wallet--aside-section {
	@include border--right-gray;
}

.info__section {
	padding-bottom: 1rem;
	.title {
		font-weight: 600;
		font-size: 16px;
		padding: 1.5rem 0 1.5rem;
	}
	section {
		display: flex;
		align-items: flex-start;
		padding-bottom: 25px;
		font-size: 14px;
		font-weight: 600;

		.key {
			padding-right: 5px;
			color: $auth-gray !important;
			margin-bottom: 0px;
			width: 50%;
		}

		.value {
			@include value;

			.hidden-text {
				display: block;
				color: $placeholder-gray;
				font-size: 12px;
				font-weight: 600;
				padding: 0.5rem 0 0;
				width: 100%;
			}
		}

		.value--link {
			@include value;

			color: $primary-deep;
			cursor: pointer;

			path {
				stroke: $primary-deep;
			}
		}
	}
}

.list-section-wrapper {
	padding-right: 20px;
	p {
		padding: 20px 30px;
		:hover {
			background: $faded-secondary;
			color: $primary;
			cursor: pointer;
		}
	}
	.active {
		background: $faded-secondary;
		color: $primary;
	}
}
