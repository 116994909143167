@import "assets/styles/scss/variables";

.text {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: $status-text;
}

.card-icon {
	margin-right: 10px;
}
