$primary: #17568b;
$primary-grad: linear-gradient(180deg, #509fef 0%, #1860a3 100%);
$primary-deep: #3d87ce;
$secondary: #509fef;
$primary-light: #86a7c3;
$primary-muted: #f7f7fa;
$faded-secondary: #F1F7FE;
$faint-secondary: #d5e8fb;

// NEUTRAL COLORS
$white: #ffffff;
$dark: #071b2b;
$black: #000000;
$light-black: #39444f;
$gray: #505050;
$gray-med: #908e8e;
$gray-light: #aaaaaa;
$gray-accent: #f6f8fa;
$gray-muted: #82898f;
$gray-fade: #b2b6ba;
$border: #ecf1f6;

// STATUS COLORS

$error: #f8c9c8;
$danger: #e31e18;
$success: #0cb95b;
$warning: #e49b0c;
$recent-danger: #f18a87;
$recent-success: #7bdfbb;
$recent-warning: #fbc984;
$recent-success-icon: #2cc7a2;
$recent-warning-icon: #f5d495;
$success-transparent: #ebfaf5;
$danger-transparent: #fdeded;
$warning-transparent: #fdf8ef;
$gray-transparent: #e0e0e0;

// GRAYS
$gray: #b2b6ba;
$auth-gray: #82898f;
$light-gray: #e5e5e5;
$faint-gray: #f6f8fa;
$input-label: #636363;
$disabled-gray: #f5f6f6;
$placeholder-gray: #a6a6a6;
$transparent-gray: #e6e6e6d3;
$dark-blue: #509fef;
$very-ligt-grey: #f1f7fe;

$status-text: #39444f;
